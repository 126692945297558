@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

.meme-portrait-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  background:black;
  text-align: center;
  height: 100%;
  margin-bottom: 5px;
}

.meme-portrait {
  height: 100%;
  width: 100%;
  grid-area: 1 / 1;
  object-fit: cover;
}

.meme-ethnicity-img {
  height: auto;
  width: 25%;
  grid-area: 1 / 1;
  justify-self: end;
  margin: 3%;
  font-size: 0
}

.meme-portrait-name-container {
  height: 5vh;
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Add ellipsis for overflow */
}

.meme-portrait-name-text  {
  color: white;
  align-items: center;
}