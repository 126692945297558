@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

/* base styles */
* {
  margin: 0;
  font-family: "Quicksand";
  color: #333;
}
.navbar {
  padding: 15px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;
}

.links {
  display: flex;
  justify-content: space-between;
  text-align: center;
  height: 50%;
}

.tooltip-div {
  display: flex;
}

.tooltip {
  margin-right: 0;
  padding-right: 5px;
}

.blue-link p {
  color: blue; /* Set the desired color */
}

.blue-link {
  text-decoration: none;
}

.blue-link:visited p {
  color: purple;
}

.navbar h1 {
  color: #000000;
}

.navbar h1:hover {
  color: red;
}

.sources-div {
  /* layout items horizontally */
  display: flex;
}

.navbar a {
  text-decoration: none;
  padding: 7px;
}
.navbar a:hover {
  color: red;
}
.content {
  width: 80vw;
  grid-area: main;
}

.sidebar {
  grid-area: sidebar;
}

.below-navbar {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-areas: "sidebar main .";
}

/* blog previews / list */
.blog-preview {
  padding: 10px 16px;
  margin: 20px 0;
  border-bottom: 1px solid #fafafa;
}
.blog-preview:hover {
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
}
.blog-preview h2 {
  font-size: 20px;
  color: red;
  margin-bottom: 8px;
}

.blog-preview a {
  text-decoration: none;
}

.main-section {
  width: 80vw;
  margin: 1vw;
}

/* blog details page */
.main-section h2 {
  font-size: 20px;
  color: #f1356d;
  margin-bottom: 10px;
}
.main-section .individual-detail-section {
  margin: 15px 0;
}

.main-section .individual-detail-social-media-section {
  display: flex;
  align-items: center; /* Align text and input */
  gap: 10px; /* Optional: Adds spacing between label and input */
}

.individual-detail-social-media-section label {
  flex: 0 1 150px; /* Allows labels to grow up to 150px but not exceed */
  min-width: 100px;
}

.individual-detail-social-media-section input {
  flex-grow: 1; /* Allow input to take up the remaining space */
  width: 100%; /* Ensures it expands fully */
  min-width: 0; /* Prevents flexbox shrink issues */
}

.main-section button {
  color: #fff;
  border: 0;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}

.individual-details {
  margin-top: 1vw;
  width: 60%;
}

.organization-details {
  width: 60%;
  margin: auto;
}

.individuals-page {
  max-width: 80%;
  margin: auto;
  display: flex;
}

.organizations-page {
  margin: auto;
  display: flex;
}

.individual-image-container {
  margin-top: 1vw;
  width: 40%;
  height: 100%;
  background-color: #f2f2f2;
  border: black 1px solid;
}

.organization-image-container {
  margin-top: 1vw;
  width: 40%;
  height: 100%;
  background-color: #f2f2f2;
  border: black 1px solid;
}

.individual-image {
  padding-top: 20px;
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.organization-page-logo {
  padding-top: 20px;
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.spacer {
  width: 5%;
}

.individual-image-details {
  padding-top: 1vw;
  width: 90%;
  display: block;
  margin: 0 auto;
  padding-bottom: 1vw;
}

/*Padding between all children of this section*/
.individual-image-details > * {
  margin-top: 5px;
}

.search-bar {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.search-bar-buttons {
  display: flex;
  justify-content: center;
  margin-top: 2px;
  justify-content: space-evenly;
}

.featured-individuals {
  width: 90%;
  margin: 0 auto;
  margin-top: 10px;
  position: relative;
  margin-bottom: 10px;
}

.individuals-summary-container {
  margin-top: 15px;
  display: flex;
  justify-content: space-around;
  grid-template-columns: 1fr auto 1fr;
}

.individual-summary {
  display: grid;
  width: 20vw;
  margin-left: 3vw;
  margin-right: 3vw;
  text-align: center;
  cursor: pointer;
}

.individual-summary-image {
  object-fit: cover;
  margin: auto;
  width: 100%;
  height: 25vw;
}

.industry-iframe {
  margin-top: 15px;
}

.featured-industry {
  width: 90%;
  margin: 0 auto;
}

.arrow-collapse {
  height: 24px;
  width: 24px;
  margin-left: auto;
  margin-right: 0;
  pointer-events: none;
}

.tags-header {
  display: flex;
}

h1 {
  font-size: 175%;
}

h2 {
  font-size: 175%;
}

.social-media-links {
  margin-top: 20px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contributed-by-section {
  text-align: center;
  margin-top: 15px;
  font-size: 0.85em;
}

.social-media-link {
  margin-left: 10px;
  margin-right: 10px;
}

.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;
  &.red {
    color: #ffffff;
    background: #d63030;
  }
  &.gray {
    color: #ffffff;
    background: #777777;
  }
  &.yellow {
    color: #000000;
    background: #ffd331;
  }
}

.pro-sidebar .pro-menu .pro-menu-item {
  font-size: 5px;
}

.about {
  margin-top: 1vw;
  margin-left: 3vw;
  margin-bottom: 3vw;
  width: 75vw;
}
.ps-menu-root .org-menu-sub-item {
  font-size: 14px;
}

.form-field label,
h2 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: rgb(84, 84, 84);
}

input {
  height: 35px;
  width: 100%;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
}

.services {
  display: flex;
  justify-content: space-between;
}
.first-division {
  display: flex;
  flex-direction: column;
  margin: 0 5px 0.5rem 0;
  width: 100%;
}
button {
  background: none;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  border-radius: 2px;
  padding: 5px 10px;
}
.first-division button {
  align-self: flex-start;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  color: rgb(0, 208, 255);
  border: 1px solid rgb(0, 208, 255);
}

.second-division button {
  color: red;
  border: 1px solid red;
}

.biography-textarea {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  font-family: "Quicksand";
}

.individual-submission-name-input {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  font-family: "Quicksand";
}

.individual-image-revert-button {
  width: 100%;
  height: 30px;
  margin-left: 5%;
}

.edit-form-tiktok-input {
  border-color: red;
  color: red;
}

.edit-form-tiktok-input-edited {
  color: green;
  border-color: green;
}

.individual-submission-input-section {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  font-family: "Quicksand";
}

.clear-date-button {
  margin-left: 20px;
}

.individual-name-container {
  display: flex;
  justify-content: space-between;
}

.navbar-user-and_new_entry_div {
  display: flex;
  padding: 10px;
  justify-content: space-between;
}

.confirmation-modal {
  color: white;
  padding: 20px;
}

.confirmation-modal-body {
  color: white;
}

.individuals-details {
  width: 65%;
  height: 100%;
}

.individual-wrapper {
  display: flex;
  align-items: center; /* Ensures image and text are aligned vertically */
}

.text-individual-image {
  height: 1em; /* Match the height of the text */
  width: auto; /* Maintain the aspect ratio */
  margin-right: 0.5em; /* Add space between the image and text */
}
