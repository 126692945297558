.modal {
  background-color: #333;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.modal p {
  color: #fff;
  width: 40em;
}

.profile-image {
  border-radius: 50%;
  cursor: pointer;
}

.modal-profile-image {
  border-radius: 50%;
  margin-top: 10px;
}

.user-points-section {
  display: inline;
  color: white;
}

.pointsText {
  color: white;
  display: inline;
  font-weight: bold;
  margin-right: 10px;
}

.goldMedal {
  height: 12px;
  width: 12px;
  background-color: gold;
  border-radius: 50%;
  margin-left: 8px;
  display: inline-block;
}

.goldText {
  color: gold;
  display: inline;
  margin-left: 2px;
}

.silverMedal {
  height: 12px;
  width: 12px;
  background-color: silver;
  border-radius: 50%;
  margin-left: 8px;
  display: inline-block;
}

.silverText {
  color: silver;
  display: inline;
  margin-left: 2px;
}

.bronzeMedal {
  height: 12px;
  width: 12px;
  background-color: #d1a684;
  border-radius: 50%;
  margin-left: 8px;
  display: inline-block;
}

.bronzeText {
  color: #d1a684;
  display: inline;
  margin-left: 2px;
}

.update-link-section {
  margin-top: 5px;
}

.contributions-explanation {
  margin: auto;
  margin-top: 2px;
  font-size: 10px;
  margin-bottom: 25px;
}

.link-input {
  background-color: white;
  padding-left: 2px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  width: 90%;
}

.update-link-button {
  padding-top: 50px;
}

.anonymous-section {
  margin-top: 50px;
}

.anonymous-label {
  margin-left: 5px;
  color: white;
}
