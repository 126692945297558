.collage {
  position: relative; /* Ensures child elements with absolute positioning are placed relative to this */
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: black;
  color: white;
  padding: 5px;
  align-items: center;
  border: 8px dashed yellow;
  min-height: 100vh;
  max-width: 70vw;
  flex-grow: 1;
  box-sizing: border-box;
}

.top-image-wrapper {
  width: 100%;
  height: 30vh; /* 15% of viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Prevents overflow */
}

.top-image-wrapper img {
  height: 100%; /* Ensures it fills the wrapper's height */
  width: auto; /* Maintains aspect ratio */
  max-width: 100%; /* Ensures it does not overflow */
  object-fit: contain; /* Ensures it scales properly */
}

.top-image-wrapper h1 {
  position: absolute;
  color: white;
  font-size: 4.0rem;
  padding: .2rem;
  text-align: center;
  text-wrap: wrap;
  z-index: 1;
}

.collage-row {
  display: grid;
  grid-template-columns: repeat(var(--img-count), 1fr);
  gap: 8px;
}

.collage-item {
  text-align: center;
  background-color: black; /* Background color of individual items */
  color: white; /* Ensure text inside items is white */
}

.collage-item img {
  width: 100%; /* Fill the container's width */
  aspect-ratio: 2 / 3; /* Portrait aspect ratio */
  object-fit: cover; /* Ensures image covers the container */
  object-position: top; /* Keeps heads visible */
  border-radius: 8px; /* Optional: Rounded corners */
}

.collage-item p {
  margin: 8px 0 0;
  font-size: var(--font-size, 18px); /* Default to 18px if no variable is set */
  text-align: center;
  color: white;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: manual;
}

.image-wrapper {
  position: relative;
  width: 100%;
  aspect-ratio: 2 / 3; /* Ensures correct portrait ratio */
  background-size: cover; /* Crops and fits like object-fit: cover */
  background-position: top; /* Keeps faces visible */
  background-repeat: no-repeat;
  border-radius: 8px; /* Optional rounded corners */
}

.star-overlay {
  position: absolute;
  top: 1.5%;
  right: 1.5%;
  max-width: 35%;
  max-height: 23.5%;
  pointer-events: none;
  z-index: 10;
}

.collage-builder-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 32px;
  color: white;
}

.bottom-section {
  display: flex;
  width: 100%;
  height: 30vh; /* 25% of viewport height instead of % of container */
  flex-shrink: 0; /* Prevents shrinking */
  align-items: center;
  justify-content: center;
  margin-top: auto; /* Pushes it to the bottom */
}

.text-container {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-size: 1.2rem;
}

.text-container p {
  color: white;
  font-size: 3vh;
}

.text-container b {
  color: white;
  font-size: 3vh;
}

.chart-container {
  width: 30%;
  height: 100%; /* Make sure it takes full height of its container */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px; /* Adjust padding if needed */
}

.chart-container > div {
  width: 100%; /* Ensure the chart fills its container */
  height: 100%;
}

/* Custom Collage Builder Section */
.custom-collage-builder {
  width: 50%;
  background: white;
  padding: 24px;
}

h1,
h2 {
  text-align: center;
}

/* Search Section */
.search-section {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
}

.search-input {
  flex: 1;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 8px;
  background: #fff;
  color: white;
}

.search-button {
  padding: 8px 16px;
  background: #ffcc00;
  color: black;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.search-button:hover {
  background: #ffaa00;
}

/* Selected List Section */
.selected-list {
  width: 100%; /* Full width of the container */
  text-align: center; /* Center the header */
}

.selected-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers list items horizontally */
  gap: 8px; /* Optional: Add space between list items */
}

.selected-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%; /* Adjust width as needed */
  max-width: 600px;
  background: #fff;
  padding: 2px 8px;
  border-radius: 8px;
  border: 1px solid black; /* Add this line */
}

.remove-button {
  background: transparent;
  color: #ff6666;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.remove-button:hover {
  color: #ff3333;
}

/* Build Button */
.build-collage-button {
  width: 100%;
  padding: 12px;
  margin-top: 16px;
  font-size: 18px;
  background: #00cc66;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.build-collage-button:hover {
  background: #00994d;
}

.footer-section {
  width: 100%;
  font-size: 1.5rem; /* Adjust font size as needed */
}

.footer-section h1 {
  color: white; /* White text */
}

.disclaimer {
  position: absolute;
  bottom: 16px;
  right: 16px;
  font-size: 0.6rem; /* Adjust font size as needed */
  color: white;
  text-align: right;
  z-index: 10; /* Ensure it's above other content */
  width: 25%; /* Occupy the right 1/3 of the collage */
  word-wrap: break-word; /* Ensures long words wrap properly */
  word-break: break-word; /* Breaks long words if necessary */
  max-width: 100%; /* Prevents it from growing beyond the container */
}


.disclaimer p {
  color: white;
}
