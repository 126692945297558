.personal-relation-builder-input-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.personal-relation-builder-input {
  width: 100%;
  border-radius: 5px;
}

.personal-relation-builder-input:focus {
  outline: 1.5px solid #2485ee;
}

.personal-relation-builder-delete-button {
  width: 45px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff0000;
  background-color: #ffffff;
  border: 1.5px solid #ff0000;
  border-radius: 5px;
  transition: all 0.4s ease;
  cursor: pointer;
}

.personal-relation-builder-delete-button:hover {
  color: #ffffff;
  background-color: #ff0000;
}

.personal-relation-builder-add-button {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #2485EE;
  background-color: #ffffff;
  border: 1.5px solid #2485EE;
  border-radius: 10px;
  padding: 6px 20px;
  margin-top: 5px;
  transition: all 0.4s ease;
}

.personal-relation-builder-add-button:hover {
  color: #ffffff;
  background-color: #2485EE;
}